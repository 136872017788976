import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import message from "../components/Message";
import { fetchData } from "../utils";

const Activate = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleActivate = async () => {
    const code = searchParams.get("code");
    if (!code) {
      message.error("code is required");
      return;
    }

    try {
      setLoading(true);
      await fetchData("/account/activate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });
      message.success("Activate successful");
      // 激活成功后跳转到登录页
      navigate("/signin");
    } catch (error) {
      message.error(
        error.response?.data?.message || "Activate failed, please try again"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-50">
      <div className="w-full max-w-sm rounded-lg bg-white p-8 shadow-lg">
        <h2 className="mb-6 text-center text-2xl font-bold text-gray-900">
          Activate account
        </h2>
        <button
          onClick={handleActivate}
          disabled={loading}
          className="w-full rounded-lg bg-primary py-3 font-medium text-white hover:bg-opacity-90 disabled:cursor-not-allowed disabled:bg-opacity-70"
        >
          {loading ? "Activating..." : "Click to activate account"}
        </button>
      </div>
    </div>
  );
};

export default Activate;
