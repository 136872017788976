import message from "../components/Message";

// export const baseUrl = "https://ujourney-web-api-c13b6d1abc16.herokuapp.com";
export const baseUrl = "/api";

export const fetchData = async (url, params) => {
  // const response = await fetch(`/api${url}`);

  // const response = await fetch(`http://backend-service.alphavisionoption-backend.svc:8000${url}`);
  // const response = await fetch(`https://backend.lb.alphavisionoption.ai${url}`);
  const response = await fetch(`${baseUrl}${url}`, params);
  console.log("response", response);
  if (response.status == 200) {
    const data = await response.json();
    return data;
  } else {
    message.error(response.statusText);
  }
};
