import React from "react";
import ReactDOM from "react-dom/client";
import Message from "./Message";

let messageContainer = null;
let root = null;
const messageQueue = [];
let isProcessing = false;

const createContainer = () => {
  if (!messageContainer) {
    messageContainer = document.createElement("div");
    messageContainer.className = "message-container";
    document.body.appendChild(messageContainer);
    root = ReactDOM.createRoot(messageContainer);
  }
};

const processQueue = () => {
  if (messageQueue.length === 0) {
    isProcessing = false;
    return;
  }

  isProcessing = true;
  const { type, content, duration } = messageQueue.shift();

  root?.render(
    <Message
      type={type}
      content={content}
      duration={duration}
      onClose={() => {
        root?.render(null);
        processQueue();
      }}
    />
  );
};

const message = {
  success(content, duration) {
    this.show("success", content, duration);
  },
  error(content, duration) {
    this.show("error", content, duration);
  },
  warning(content, duration) {
    this.show("warning", content, duration);
  },
  info(content, duration) {
    this.show("info", content, duration);
  },
  show(type, content, duration) {
    createContainer();
    messageQueue.push({ type, content, duration });
    if (!isProcessing) {
      processQueue();
    }
  },
};

export default message;
