import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

// export type MessageType = 'success' | 'error' | 'info' | 'warning';

// interface MessageProps {
//   type: MessageType;
//   content: string;
//   duration?: number;
//   onClose: () => void;
// }

const Message = ({ type, content, duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(onClose, 300); // 等待动画结束后再移除
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getIcon = () => {
    switch (type) {
      case "success":
        return (
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "error":
        return (
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "warning":
        return (
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        );
      default:
        return (
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        );
    }
  };

  const getTypeStyles = () => {
    switch (type) {
      case "success":
        return "bg-green-50 text-green-500 dark:bg-green-900/30 dark:text-green-300";
      case "error":
        return "bg-red-50 text-red-500 dark:bg-red-900/30 dark:text-red-300";
      case "warning":
        return "bg-yellow-50 text-yellow-500 dark:bg-yellow-900/30 dark:text-yellow-300";
      default:
        return "bg-blue-50 text-blue-500 dark:bg-blue-900/30 dark:text-blue-300";
    }
  };

  return createPortal(
    <div
      className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-[10001] transition-all duration-300 ${
        visible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
      }`}
    >
      <div
        className={`flex items-center px-4 py-2 rounded-lg shadow-lg ${getTypeStyles()}`}
      >
        <span className="mr-2">{getIcon()}</span>
        <span className="text-sm font-medium">{content}</span>
      </div>
    </div>,
    document.body
  );
};

export default Message;
