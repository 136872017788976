import React, { useEffect, useRef } from "react";

/**
 * 组件完成渲染后的钩子，只执行一次
 * @param callback
 * @returns
 */
export default function useMountEffect(callback) {
  const flag = useRef(true);

  useEffect(() => {
    if (!flag.current) return;
    flag.current = false;
    return callback();
  }, []); // 依赖数组为空，确保仅在组件挂载时执行

  return flag;
}
